.header {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  z-index: 9999;
  flex-direction: column;

  &-title {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 15px 30px;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Ruslan Display', cursive;
    border: 0;
  }

  label {
    padding: 15px 30px;
    display: inline-flex;
    text-shadow: 1px 1px 2px #969696;
    justify-content: center;
    align-items: center;
    font-size: .8rem;

    input {
      margin-right: .5rem;
    }
  }
}

.infoCard {
  margin-top: 5px;
  background-color: #fff;
  padding: 15px 30px;
  font-family: sans-serif;
  width: 100%;
  max-width: 446px;

  p {
    font-size: .8rem;
  }
}

@media (max-width: 1024px) {
  .header {
    padding-bottom: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    text-align: center;
    width: 100%;
    background-color: #fff;

    &-title {
      font-size: 1rem;
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
    }

    label {
      padding: 15px 15px 5px 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      text-shadow: unset;
      position: absolute;
      top: -45px;
      right: -5px;
    }
  }

  .infoCard {
    max-width: 100%;
  }
}
