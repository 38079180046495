@font-face {
  font-family: 'Ruslan Display';
  src: url('../fonts/RuslanDisplay-Regular.ttf') format('truetype');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';
