.copyright {
  position: absolute;
  z-index: 9999;
  bottom: 3px;
  left: 10px;
  background-color: transparent;
  display: flex;
  font-size: 0.65rem;
  color: #444;
  font-family: sans-serif;
}

@media (max-width: 800px) {
  .copyright {
    display: none;
    //position: absolute;
    //bottom: 15px;
    //left: 0;
    //background-color: transparent;
    //display: flex;
    //font-size: .75rem;
    //color: #444;
    //max-width: calc(100% - 30px);
  }
}
